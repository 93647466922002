import { Route} from 'react-router-dom';

import AssetsView from 'views/Assets/AssetsView';
import BadgesView from 'views/Badges/BadgesView';
import CouponsView from 'views/Coupons/CouponsView';
import LocalizationView from 'views/Localization/LocalizationView';
import OffersView from 'views/Bundles/OffersView';
import OffersUIView from 'views/OffersUI/OffersUIView';
import PopupsView from 'views/Popups/PopupsView';
import ProductsView from 'views/Products/ProductsView';
import ProfileView from 'views/Profile/ProfileView';
import PromotionsView from 'views/Promotions/PromotionsView';
import SegmentsView from 'views/Segments/SegmentsView';
import StatisticsView from 'views/Statistics/StatisticsView';

import PricingView from 'components/Pricing/PricingView';
import SettingsView from 'components/Settings/SettingsView';
import ThemeView from 'components/Theme/Theme';

const BackOfficeRoutes = (
    <Route path=":id/*">
        <Route path="statistics" element={<StatisticsView />} />
        <Route path="segments/*" element={<SegmentsView />} />
        <Route path="settings" element={<SettingsView />} />
        <Route path="profile" element={<ProfileView />} />
        <Route path="theme/*" element={<ThemeView />} />
        <Route path="products/*" element={<ProductsView />} />
        <Route path="offersui/*" element={<OffersUIView />} />
        <Route path="coupons/*" element={<CouponsView />} />
        <Route path="badges/*" element={<BadgesView />} />
        <Route path="assets" element={<AssetsView />} />
        <Route path="offers/*" element={<OffersView />} />
        <Route path="promotions/*" element={<PromotionsView />} />
        <Route path="popups/*" element={<PopupsView />} />
        <Route path="pricing/*" element={<PricingView />} />
        <Route path="localization/*" element={<LocalizationView />} />
    </Route>
);

export default BackOfficeRoutes;
