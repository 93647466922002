import { useEffect } from 'react';

import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { uiActions } from 'store/uiSlice';

import { User } from '../../common/contracts';
import LoginModal from '../../components/LoginModal/LoginModal';
import { NEW_VERSION } from '../../constants/constants';
import { ELocalStorageKeys } from '../../constants/enums';
import { useLogin } from '../../hooks/useLogin';
import { localStorageUtil } from '../../utils/localStorageUtil';

import './style.scss';

const LoginPage = () => {
  const login = useLogin();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const versionDetails = localStorageUtil.getAny<User>(
    ELocalStorageKeys.USER_DETAILS
  )?.version;

  useEffect(() => {
    if (login.isLoggedIn()) {
      const urlParams = new URLSearchParams(window.location.search);
      const redirectTarget = urlParams.get('t');
      if (redirectTarget) {
        login.logout();
        dispatch(uiActions.setTokenLoading(false));
      }
      const navigationPath =
        versionDetails === NEW_VERSION
          ? `./project`
          : `./project/statistics?range=2`;
      navigate(navigationPath);
    }
  }, [login, navigate, versionDetails]);

  return login.isLoggedIn() ? null : (
    <div id="loginPage">
      <div
        style={{
          width: '50%'
        }}
      ></div>
      <img
        className="logo"
        alt="logo"
        src={`${process.env.PUBLIC_URL}/assets/images/logo_purple.svg`}
        style={{ marginBottom: '30px' }}
      />
      <LoginModal />
      <div className="strokes">
        <div></div>
        <div></div>
      </div>
    </div>
  );
};

export default LoginPage;
