import { Route, Routes, useNavigate } from 'react-router-dom';
import PrivateRoute from 'utils/privateRoute';
import { ErrorBoundary } from 'react-error-boundary';
import BackOffice from 'pages/BackOffice/BackOffice';
import { ErrorFallback } from '@appcharge/shared-ui';
import LoginPage from 'pages/Login/Login';
import BackOfficeRoutes from './BackOfficeRoutes';
import OrdersView from '../views/Orders/OrdersView';
import ProjectsView from '../views/Projects/ProjectsView';
import ReportsView from '../views/Reports/ReportsView';
import FinancialReportsTable from '../views/Reports/FinancialReports';
import PayoutsTable from '../views/Reports/Payouts';
import ProfileView from '../views/Profile/ProfileView';


const AppRoutes = () => {
    const navigate = useNavigate();
    const handleNavigate = () => {
        navigate('../');
    };
    return (
        <Routes>
            <Route
                path="/project/*"
                element={
                    <PrivateRoute>
                        <ErrorBoundary FallbackComponent={() => <ErrorFallback handleClick={handleNavigate} />}>
                            <BackOffice />
                        </ErrorBoundary>
                    </PrivateRoute>
                }
            >
                <Route index element={<ProjectsView />} />
                <Route path="orders/*" element={<OrdersView />} />
                <Route path="reports/*" element={<ReportsView />}>
                    <Route
                        index
                        path="financialreports"
                        element={<FinancialReportsTable />}
                    />
                    <Route path="payouts" element={<PayoutsTable />} />
                </Route>
                <Route path="profile" element={<ProfileView />} />
                {BackOfficeRoutes}
            </Route>

            <Route path="/:error?" element={<LoginPage />} />
        </Routes>
    )
};

export default AppRoutes;
