import { useState } from 'react';

import { Dayjs } from 'dayjs';
import Stack from '@mui/system/Stack';

import AcSearchInput from 'components/AcInput/AcSearchInput';
import DatePickerOverlay from 'components/DatePickerOverlay/DatePickerOverlay';

interface DateRangeFilterProps {
  handleDateApply: (range: any, hours: [string, string]) => void;
  rangeDates?: [Dayjs, Dayjs];
  setRangeDates: (dates?: [Dayjs, Dayjs]) => void;
}

export function DateRangeFilter({
  handleDateApply,
  rangeDates,
  setRangeDates
}: DateRangeFilterProps) {
  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);
  // const [datePickerSearch, setDatePickerSearch] = useState(false);

  const formattedDate = rangeDates?.length
    ? `${rangeDates?.[0].format(
        'DD/MM/YY'
      )} - ${rangeDates?.[1].format('DD/MM/YY')}`
    : '';

  return (
    <>
      <DatePickerOverlay
        isOpen={Boolean(isDatePickerOpen)}
        isHourPicker={false}
        setIsOpen={setIsDatePickerOpen}
        onApply={(range: any, hours: [string, string]) => {
          handleDateApply(range, hours);
          setIsDatePickerOpen(false);
        }}
        onApplyButtonText={'Apply'}
        shouldValidateDaysDiff={false}
      />
      <Stack direction="column" justifyContent="center">
        <AcSearchInput
          size="small"
          header="Creation date"
          sx={{ flexGrow: 1 }}
          onClearClick={() => setRangeDates(undefined)}
          onClick={() => setIsDatePickerOpen(true)}
          id="outlined-adornment-weight"
          setValue={setIsDatePickerOpen}
          value={formattedDate || ''}
          isDate={true}
        />
      </Stack>
    </>
  );
}
