import SidebarMenuOptionsGroup from '../MenuOptionsGroup/SidebarMenuOptionsGroup';
import { SidebarMenuItem } from '../MenuOptionsGroup/SidebarMenuOptionsGroup.types';
import { capitalizeFirstLetter } from 'utils/formattingUtil';
import { ESideBarGroup } from 'constants/enums';
import { useMemo } from 'react';

const SidebarMenu = ({navItems}: {navItems: SidebarMenuItem[]}) => {
   const menuOptionsByGroup = useMemo(() => (
       navItems
           .filter(item => !item.hide)
           .reduce((acc, item) => {
               acc[item.group] = acc[item.group] || [];
               acc[item.group].push(item);
               return acc
           }, {} as Record<string, SidebarMenuItem[]>)
   ), [navItems])

    if (Object.keys(menuOptionsByGroup).length === 0) return  null;

    return (
        <>
            {Object.keys(menuOptionsByGroup).map((groupName, index) => (
               <ul key={index} className="navGroup">
                  <SidebarMenuOptionsGroup
                     name={groupName !== ESideBarGroup.OVERVIEW ? capitalizeFirstLetter(groupName) : undefined}
                     items={menuOptionsByGroup[groupName]}
                  />
               </ul>
            ))}
        </>
    )
}

export default SidebarMenu;
