import React, { useMemo, useState } from 'react';

import { Stack } from '@mui/system';
import { useFormik } from 'formik';
import useLocalization from 'api/useLocalization';
import { getCountriesOptions, getSupportedLanguageCodes, handleRenderCountriesList } from 'views/Localization/utils';

import AcInput from 'components/AcInput/AcInput';
import AcSelect from 'components/AcSelect/AcSelect';
import ActionButton from 'components/ActionButton/ActionButton';
import { LanguageDataParams } from 'views/Localization/components/types';

import { EditLanguageFormProps } from './EditLanguageForm.types';
import { editLanguageSchema } from './validation-schema';

import './style.scss';

const EditLanguageForm = ({
  selectedLanguage,
  onSave
}: EditLanguageFormProps) => {
  const [selectedCountries, setSelectedCountries] = useState<string[]>(
    selectedLanguage?.country ?? []
  );

  const countriesOptions = useMemo(() => getCountriesOptions(), []);
  const languageCodesOptions = useMemo(() => getSupportedLanguageCodes(), []);
  const { getAddedLanguagesList} = useLocalization();

  const handleSelectCountry = (arrSelectedCountries: string[]) => {
    const newSelectedValues = arrSelectedCountries.filter(
      (item: string) => item !== ''
    );
    setFieldValue('country', newSelectedValues, true);
    setSelectedCountries(newSelectedValues);
  };

  const addedLanguagesList = getAddedLanguagesList.data.filter((item: LanguageDataParams) => item.language.toLowerCase() !== selectedLanguage?.language.toLowerCase());
  const existingLanguageCodesList = getAddedLanguagesList?.data?.filter((item: LanguageDataParams) => item.isoCode.toLowerCase() !== selectedLanguage?.isoCode.toLowerCase());

  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    submitForm,
    isValid,
    setFieldValue
  } = useFormik({
    initialValues: {
      language: selectedLanguage?.language ?? '',
      country: selectedLanguage?.country ?? [],
      isEnabled: selectedLanguage?.isEnabled ?? false,
      isoCode: selectedLanguage?.isoCode ?? ''
    },
    validationSchema: editLanguageSchema(addedLanguagesList, existingLanguageCodesList),
    validateOnBlur: true,
    validateOnChange: false,
    onSubmit: (values, { resetForm }) => {
      const parsedLanguageData = values.country.map((country) => ({
        language: values.language,
        country:
          (countriesOptions.find((option) => option.value === country)
            ?.key as string) ?? '',
        isEnabled: values.isEnabled,
        isoCode: values.isoCode
      }));

      onSave(parsedLanguageData);
      setSelectedCountries([]);
      resetForm();
    }
  });

  return (
    <form className="editLanguageForm" onSubmit={handleSubmit}>
      <Stack
        direction="row"
        alignItems="flex-start"
        className="editLanguageFormContainer"
      >
        <AcInput
          header="Language Display Name"
          name="language"
          value={values.language}
          onChange={handleChange}
          onBlur={handleBlur}
          customClass="editLanguageForm__input"
          error={touched.language && !!errors.language}
          helperText={(touched.language && errors.language) || ''}
        />
        <AcSelect
            name="isoCode"
            header="Language Code"
            value={values.isoCode}
            items={languageCodesOptions}
            onChange={handleChange}
            isApplyBtnEnabled={false}
            className="editLanguageForm__select"
            error={!!errors.isoCode}
            helperText={(errors.isoCode as string) || ''}
            isSearchEnabled
        />
        <AcSelect
          name="country"
          header="Country"
          value={values.country}
          setSelectedItems={(values: string[]) => handleSelectCountry(values)}
          items={countriesOptions}
          isApplyBtnEnabled={false}
          renderValue={() => handleRenderCountriesList(selectedCountries)}
          className="editLanguageForm__select"
          error={!!errors.country}
          helperText={(errors.country as string) || ''}
          isCheckBox
          isSearchEnabled
        />
        <ActionButton
          text="Save"
          onClick={submitForm}
          disabled={!isValid}
        />
      </Stack>
    </form>
  );
};

export default EditLanguageForm;
