import { useEffect, useState } from 'react';

import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import DeleteOutlineTwoToneIcon from '@mui/icons-material/DeleteOutlineTwoTone';
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import LinkIcon from '@mui/icons-material/Link';
import ScheduleIcon from '@mui/icons-material/Schedule';
import { CircularProgress } from '@mui/material';
import { GridActionsCellItem, GridRowParams } from '@mui/x-data-grid';
import useDeeplinks from 'api/useDeeplinks';
import useUsers from 'api/useUsers';
import { Offer, ScheduleTimeFrame, User } from 'common/contracts';
import { EPermissionAction, EPermissionGroup } from 'common/permissions.types';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { StatusLabel } from 'design-system/StatusLabel/StatusLabel';
import { EStatusLabel } from 'design-system/StatusLabel/types';
import { localStorageUtil } from 'utils/localStorageUtil';

import CustomizedSwitch from 'components/SwitchButton/SwitchButton';

import useOffers from '../../api/useOffers';
import AcContentWrapper from '../../components/AcContentWrapper/AcContentWrapper';
import AcViewWrapper from '../../components/AcViewWrapper/AcViewWrapper';
import DataTable from '../../components/DataTable/DataTable';
import DialogModal from '../../components/Dialog/Dialog';
import FirstActionModal from '../../components/FirstActionModal/FirstActionModal';
import PageTopBar from '../../components/Topbar/PageTopBar';
import {
  DATE_TIME_FORMAT,
  DATE_TIME_FORMAT_UTC
} from '../../constants/constants';
import {
  EAppearanceStatus,
  EButtonColor,
  EFeatureFlag,
  ELocalStorageKeys,
  ENotificationType,
  EScheduleType,
  OfferType
} from '../../constants/enums';
import { useNotifications } from '../../hooks/useNotifications';
import { usePaginationFromURL } from '../../hooks/usePaginationFromURL';
import { AuthSliceState } from '../../store/store.types';
import { getConvertedPrice } from '../../utils/getConvertedPrice';
import { permissionsUtil } from '../../utils/permissionsUtil';

import { determineStatus } from './utils';

const dayjsUTC = dayjs.extend(utc);

const OffersTable = () => {
  const currentPublisherId = useSelector(
    ({ auth }: { auth: AuthSliceState }) => auth.currentPublisherId
  );
  const navigate = useNavigate();
  const location = useLocation();
  const { generateOrGetDeeplink } = useDeeplinks(currentPublisherId);
  const [generatingDeeplink, setGeneratingDeeplink] = useState(false);
  const { page, rows } = usePaginationFromURL('offers');
  const [currentPage, setCurrentPage] = useState(page);
  const [rowsPerPage, setRowsPerPage] = useState(rows);
  const {
    getOffers: offers,
    deleteOffer,
    updateOffer
  } = useOffers(undefined, OfferType.SPECIAL_OFFER, {
    currentPage,
    rowsPerPage
  });
  const totalCount = offers.data?.totalCount || 0;
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [selectedOffer, setSelectedOffer] = useState<string | null>(null);
  const { enqueueSnackbar } = useNotifications();

  useEffect(() => {
    navigate(`${location.pathname}?page=${currentPage}&rows=${rowsPerPage}`, {
      replace: true
    });
    offers.refetch();
  }, [currentPage, rowsPerPage]);

  const versionDetails = localStorageUtil.getAny<User>(
    ELocalStorageKeys.USER_DETAILS
  )?.version;
  const { fetchFeatureFlags } = useUsers(false, currentPublisherId, true);

  const hasScheduleOfferFeatureFlag =
    fetchFeatureFlags.data?.featureFlags?.[
      EFeatureFlag.DASHBOARD_SCHEDULED_OFFERS
    ];

  const hasDeeplinkOfferFeatureFlag =
    fetchFeatureFlags.data?.featureFlags?.[
      EFeatureFlag.DASHBOARD_GENERATE_DEEPLINK
    ];

  const filteredOffers = offers.data?.offers?.filter((offer: Offer) => {
    if (hasScheduleOfferFeatureFlag) {
      return true; // Include all offers when the feature flag is on
    }
    return offer?.schedule?.permanent || !offer?.schedule; // Only include offers with permanent schedules
  });

  const handleDeleteOffer = async () => {
    if (!selectedOffer) return;
    await deleteOffer.mutate(selectedOffer, {
      onSuccess: () => {
        enqueueSnackbar(
          'Promotion deleted successfully',
          ENotificationType.SUCCESS
        );
        offers.refetch();
      },
      onError: (e: any) => {
        enqueueSnackbar(
          'Failed to delete promotion',
          ENotificationType.ERROR,
          e.response.data.message ?? ''
        );
      },
      onSettled: () => {
        setIsDeleteDialogOpen(false);
        setSelectedOffer(null);
      }
    });
  };

  const statusPriority = {
    [EAppearanceStatus.RUNNING]: 3,
    [EAppearanceStatus.UPCOMING]: 2,
    [EAppearanceStatus.ENDED]: 1
  };

  const RowContentWrapper = ({ children }: { children: React.ReactNode }) => {
    return <div className="row-content">{children}</div>;
  };

  const handleGetDeeplink = async (offerId: string) => {
    setGeneratingDeeplink(true);
    await generateOrGetDeeplink.mutateAsync(
      { offerId },
      {
        onSuccess: (data: any) => {
          // Try using the clipboard API first
          navigator.clipboard
            ?.writeText(data.deeplink)
            .then(() => {
              enqueueSnackbar(
                'Deeplink generated successfully and copied to clipboard',
                ENotificationType.SUCCESS
              );
            })
            .catch(() => {
              // Fallback for Safari: Create a temporary textarea
              const textarea = document.createElement('textarea');
              textarea.value = data.deeplink;
              textarea.style.position = 'fixed'; // Avoid scrolling to bottom
              document.body.appendChild(textarea);
              textarea.focus();
              textarea.select();

              try {
                document.execCommand('copy');
                enqueueSnackbar(
                  'Deeplink generated successfully and copied to clipboard',
                  ENotificationType.SUCCESS
                );
              } catch (err) {
                enqueueSnackbar(
                  'Deeplink generated successfully. Please copy it manually: ' +
                    data.deeplink,
                  ENotificationType.WARNING
                );
              } finally {
                document.body.removeChild(textarea);
              }
            });
        },
        onError: (error: any) => {
          console.log('error', error);
          enqueueSnackbar(
            'Something went wrong generating the deeplink',
            ENotificationType.ERROR
          );
        },
        onSettled: () => {
          setGeneratingDeeplink(false);
        }
      }
    );
  };

  const getRowClassName = (params: GridRowParams<any>): string => {
    const timeFramesData = (params.row as Offer)?.schedule?.timeFrames;
    const isPermanent = (params.row as Offer)?.schedule?.permanent;
    if (isPermanent) {
      return '';
    }
    if (timeFramesData && timeFramesData.length > 0) {
      const { status } = getStrongestStatusWithTime(timeFramesData);
      if (status === EAppearanceStatus.ENDED) return 'row-ended';
    }
    return '';
  };

  const getStrongestStatusWithTime = (
    timeFrames?: ScheduleTimeFrame[]
  ): {
    status: EAppearanceStatus | EScheduleType.PERMANENT;
    appearanceTime: string;
  } => {
    if (!timeFrames?.length)
      return {
        status: EScheduleType.PERMANENT,
        appearanceTime: '-'
      };
    const initialAccumulator = {
      status: EAppearanceStatus.ENDED,
      appearanceTime: '-'
    };

    const { status, appearanceTime } = (timeFrames ?? []).reduce(
      (acc, timeFrame) => {
        const { startTime, endTime } = timeFrame;
        const currentStatus = determineStatus(
          new Date(startTime),
          new Date(endTime)
        );

        if (statusPriority[currentStatus] > statusPriority[acc.status]) {
          return {
            status: currentStatus,
            appearanceTime: dayjs.utc(startTime).format(DATE_TIME_FORMAT_UTC)
          };
        }

        return acc;
      },
      initialAccumulator
    );

    return { status, appearanceTime };
  };

  const columns = [
    {
      field: 'offerId',
      flex: 1,
      headerName: 'ID',
      width: 100,
      renderCell: (params: any) => (
        <RowContentWrapper>{params.row.offerId}</RowContentWrapper>
      )
    },
    {
      field: 'publisherOfferId',
      flex: 1,
      headerName: 'SKU',
      width: 100,
      renderCell: (params: any) => (
        <RowContentWrapper>{params.row.publisherOfferId}</RowContentWrapper>
      )
    },
    {
      field: 'offerUi.name',
      flex: 1,
      headerName: 'Offer Design',
      width: 100,
      renderCell: (params: any) => {
        if (offers.isLoading) return '';
        return (
          <RowContentWrapper> {params.row.offerUi?.name} </RowContentWrapper>
        );
      }
    },
    {
      field: 'name',
      flex: 1,
      headerName: 'Name',
      width: 100,
      renderCell: (params: any) => (
        <RowContentWrapper>{params.row.name}</RowContentWrapper>
      )
    },
    {
      field: 'createdAt',
      flex: 1,
      headerName: 'Created at',
      width: 200,
      type: 'date',
      renderCell: (params: any) => {
        if (offers.isLoading) return '';
        return (
          <RowContentWrapper>
            {dayjsUTC.utc(params.row.createdAt).format(DATE_TIME_FORMAT)}
          </RowContentWrapper>
        );
      }
    },
    {
      field: 'isActive',
      flex: 0.5,
      headerName: 'Is Active',
      width: 150,
      renderCell: (params: any) => {
        const { offerId, active } = params.row;
        return (
          <CustomizedSwitch
            status={active}
            texts={[]}
            functions={[
              () => togglePromotionStatus(offerId, true),
              () => togglePromotionStatus(offerId, false)
            ]}
          />
        );
      }
    },
    {
      field: 'schedule',
      flex: 1,
      headerName: 'Schedule Status',
      width: 250,
      type: 'date',
      renderCell: (params: any) => {
        const { status } = getStrongestStatusWithTime(
          (params.row as Offer)?.schedule?.timeFrames
        );
        if (
          (params.row as Offer)?.schedule?.permanent ||
          !(params.row as Offer)?.schedule
        ) {
          return (
            <StatusLabel
              text={EScheduleType.PERMANENT}
              status={EStatusLabel.ACTIVE}
              prefixIcon={<CheckCircleOutlineIcon />}
            />
          );
        } else {
          return (
            <StatusLabel
              text={status}
              status={
                status === EAppearanceStatus.RUNNING
                  ? EStatusLabel.ACTIVE
                  : status === EAppearanceStatus.UPCOMING
                    ? EStatusLabel.PENDING
                    : EStatusLabel.FAILED
              }
              prefixIcon={
                status === EAppearanceStatus.RUNNING ? (
                  <CheckCircleOutlineIcon />
                ) : (
                  <ScheduleIcon />
                )
              }
            />
          );
        }
      }
    },
    {
      field: 'nextAppearance',
      flex: 1,
      headerName: 'Next Appearance',
      width: 200,
      renderCell: (params: any) => {
        const schedule = (params.row as Offer)?.schedule;

        if (schedule?.permanent) {
          return <RowContentWrapper>-</RowContentWrapper>; // Display "-" if permanent
        }
        const appearanceTime = getStrongestStatusWithTime(
          schedule?.timeFrames
        ).appearanceTime;
        return <RowContentWrapper>{appearanceTime}</RowContentWrapper>;
      }
    },
    {
      field: 'price',
      flex: 0.5,
      headerName: 'Price',
      width: 100,
      renderCell: (params: any) => {
        if (offers.isLoading) return '';
        return (
          <RowContentWrapper>{getConvertedPrice(params)}</RowContentWrapper>
        );
      }
    },
    {
      field: 'actions',
      flex: 0,
      type: 'actions',
      width: 80,
      disableReorder: true,
      getActions: (params: any) =>
        [
          <GridActionsCellItem
            icon={<EditTwoToneIcon />}
            label="Edit"
            onClick={() => {
              navigate(`./form/${params.id}`);
            }}
            disabled={
              !permissionsUtil.canUserEdit() ||
              (permissionsUtil.isNewVersion() &&
                !permissionsUtil.isActionEnabled(
                  null,
                  EPermissionGroup.OFFERS,
                  currentPublisherId,
                  EPermissionAction.MANAGE
                ))
            }
            showInMenu
          />,
          <GridActionsCellItem
            icon={<ContentCopyOutlinedIcon />}
            label="Duplicate"
            onClick={() => {
              navigate(`./dup/${params.id}`);
            }}
            disabled={
              !permissionsUtil.canUserEdit() ||
              (permissionsUtil.isNewVersion() &&
                !permissionsUtil.isActionEnabled(
                  null,
                  EPermissionGroup.OFFERS,
                  currentPublisherId,
                  EPermissionAction.MANAGE
                ))
            }
            showInMenu
          />,
          params.row.productsSequence.some(
            ({ priceInUsdCents }: { priceInUsdCents: number }) =>
              priceInUsdCents > 0
          ) && hasDeeplinkOfferFeatureFlag ? (
            <GridActionsCellItem
              icon={
                generatingDeeplink ? (
                  <CircularProgress size={20} />
                ) : (
                  <LinkIcon />
                )
              }
              label="Get Deeplink"
              onClick={(e: any) => {
                e.stopPropagation();
                handleGetDeeplink(params.row.offerId);
              }}
              disabled={!params.row.active || generatingDeeplink}
              showInMenu
            />
          ) : null,
          <GridActionsCellItem
            className="danger"
            icon={<DeleteOutlineTwoToneIcon className="danger" />}
            label="Delete"
            onClick={() => {
              setIsDeleteDialogOpen(true);
              setSelectedOffer(params.id);
            }}
            disabled={
              !permissionsUtil.canUserEdit() ||
              (permissionsUtil.isNewVersion() &&
                !permissionsUtil.isActionEnabled(
                  null,
                  EPermissionGroup.OFFERS,
                  currentPublisherId,
                  EPermissionAction.MANAGE
                ))
            }
            showInMenu
          />
        ].filter(Boolean)
    }
  ];

  const togglePromotionStatus = async (offerId: string, active: boolean) => {
    updateOffer.mutate(
      { offerId, form: { active: !active } },
      {
        onSuccess: () => {
          enqueueSnackbar(
            active
              ? 'Promotion deactivated successfully'
              : 'Promotion activated successfully',
            ENotificationType.SUCCESS
          );
          offers.refetch();
        },
        onError: () => {
          enqueueSnackbar(
            'Error editing special offer',
            ENotificationType.ERROR
          );
        }
      }
    );
  };

  return (
    <>
      <AcViewWrapper
        header={
          <PageTopBar
            headline="Promotions"
            buttons={[
              {
                text: 'Add New Promotion',
                action: () => navigate('./form'),
                disabled: !permissionsUtil.canUserEdit(),
                hidden:
                  permissionsUtil.isNewVersion() &&
                  !permissionsUtil.isActionEnabled(
                    null,
                    EPermissionGroup.OFFERS,
                    currentPublisherId,
                    EPermissionAction.MANAGE
                  )
              }
            ]}
          />
        }
      >
        <AcContentWrapper>
          <DataTable
            pagination={true}
            rowIdIdentifier="offerId"
            columns={columns}
            rows={filteredOffers}
            loading={offers.isLoading || offers.isFetching}
            onPageChange={(newPage) => setCurrentPage(newPage)}
            onRowsPerPageChange={(newRowsPerPage) => {
              setRowsPerPage(newRowsPerPage);
              setCurrentPage(0);
            }}
            currentPage={currentPage}
            totalCount={totalCount}
            defaultHiddenFields={[]}
            localStorageColumnsKey={ELocalStorageKeys.ORDERS_COLUMN_VISIBILITY}
            hideFooter={false}
            error={false}
            initialSorting={{
              sortModel: [{ field: 'createdAt', sort: 'desc' }]
            }}
            onNoData={
              <FirstActionModal
                headline="Promotions convert better"
                text="Create your first one by hitting the “Add new Promotion” button on the top right corner of the screen"
              />
            }
            getRowClassName={getRowClassName}
            // columnVisibilityModel={columnVisibilityModel}
          />
        </AcContentWrapper>
      </AcViewWrapper>

      <DialogModal
        isOpen={isDeleteDialogOpen}
        headline="Delete Promotion"
        text="Are you sure you want to delete this promotion?"
        buttons={[
          {
            text: 'Cancel',
            color: EButtonColor.SECONDARY,
            variant: 'outlined',
            func: () => {
              setIsDeleteDialogOpen(false);
            }
          },
          {
            text: 'Delete',
            color: EButtonColor.ERROR,
            variant: 'contained',
            func: handleDeleteOffer
          }
        ]}
        closeDialog={() => setIsDeleteDialogOpen(false)}
      />
    </>
  );
};

export default OffersTable;
