import { useEffect, useState } from 'react';

import { useIdleTimer } from 'react-idle-timer';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet } from 'react-router-dom';

import { useSetCssVars } from '@appcharge/shared-ui';
import { LinearProgress } from '@mui/material';
import useTheme from 'api/useTheme';
import useUsers from 'api/useUsers';
import {
  DEFAULT_LOGOUT_TIMEOUT_LONG,
  DEFAULT_LOGOUT_TIMEOUT_SHORT
} from 'constants/constants';
import { EButtonColor, ELocalStorageKeys } from 'constants/enums';
import { authActions } from 'store/authSlice';
import { AuthSliceState, UISliceState } from 'store/store.types';
import { uiActions } from 'store/uiSlice';
import { localStorageUtil } from 'utils/localStorageUtil';
import { unsavedChangesUtil } from 'utils/unsavedChangesUtil';

import DialogModal from 'components/Dialog/Dialog';
import Sidebar from 'components/Sidebar/Sidebar';

import './style.scss';

const BackOffice = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState<boolean>(true);
  const uiState = useSelector(({ ui }: { ui: UISliceState }) => ui);
  const dispatch = useDispatch();

  const currentPublisherId = useSelector(
    ({ auth }: { auth: AuthSliceState }) => auth.currentPublisherId
  );

  const { getStoreTheme } = useTheme(currentPublisherId);
  useSetCssVars(getStoreTheme.data, document);

  const { logout } = useUsers(false, currentPublisherId, false);
  const url = window.location.href;

  const DEFAULT_LOGOUT_TIMEOUT =
    url.includes('dashboard-dev') || url.includes('dashboard-staging')
      ? DEFAULT_LOGOUT_TIMEOUT_LONG
      : DEFAULT_LOGOUT_TIMEOUT_SHORT;

  useEffect(() => {
    return () => {
      dispatch(authActions.clearState());
    };
  }, [dispatch]);

  const onIdle = () => {
    const now = new Date();
    const lastActiveTime = getLastActiveTime();
    if (lastActiveTime) {
      const idleDuration = now.getTime() - lastActiveTime.getTime();

      if (idleDuration >= DEFAULT_LOGOUT_TIMEOUT) {
        logout.mutate();
      }
    }
  };

  const { getLastActiveTime } = useIdleTimer({
    timeout: DEFAULT_LOGOUT_TIMEOUT,
    onIdle
  });

  useEffect(() => {
    dispatch(uiActions.setTokenLoading(false));
  }, [dispatch]);

  return (
    <div id="backOffice">
      {localStorageUtil.get(ELocalStorageKeys.MOCK_API) &&
        Object.entries(
          localStorageUtil.get<Record<string, boolean>>(
            ELocalStorageKeys.MOCK_API
          )
        ).find(([_, v]) => !!v) && (
          <div
            style={{
              background: 'red',
              position: 'fixed',
              zIndex: 9999,
              width: '100vw',
              color: 'white',
              textAlign: 'center'
            }}
          >
            SOME ROUTES MOCKED
          </div>
        )}
      <LinearProgress
        value={0}
        sx={{
          transition: 'opacity 0.5s',
          opacity: uiState.globalLoading ? 1 : 0,
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          zIndex: 999
        }}
      />
      <Sidebar
        updateMainSize={setIsSidebarOpen}
        isSidebarOpen={isSidebarOpen}
      />
      <div id="main" className={isSidebarOpen ? '' : 'collapsed'}>
        <Outlet />
      </div>
      <DialogModal
        isOpen={uiState.isUnsavedChanges && uiState.isUnsavedChangesModalOpen}
        headline="Unsaved Changes"
        text="You have unsaved changes. Are you sure you want to leave?"
        buttons={[
          {
            text: 'Cancel',
            color: EButtonColor.SECONDARY,
            variant: 'outlined',
            func: () => {
              dispatch(uiActions.setUnsavedChangesModalOpen(false));
            }
          },
          {
            text: 'Leave',
            color: EButtonColor.PRIMARY,
            variant: 'contained',
            func: () => {
              unsavedChangesUtil.func();
              dispatch(uiActions.setUnsavedChangesModalOpen(false));
              dispatch(uiActions.setUnsavedChanges(false));
            }
          }
        ]}
        closeDialog={() => {
          dispatch(uiActions.setUnsavedChangesModalOpen(false));
          dispatch(uiActions.setUnsavedChanges(false));
        }}
      />
    </div>
  );
};

export default BackOffice;
