import React from 'react';
import { Skeleton, Stack } from '@mui/material';

export const SidebarSkeleton: React.FC = () => (
    <>
      <Stack direction="row" gap={2} py={2} justifyContent="center">
        <Skeleton variant="circular" width={40} height={40} />
        <Skeleton variant="text" width={'60%'} />
      </Stack>
      <Stack alignItems="center" gap={4}>
        {[1, 2, 3, 4, 5, 6].map((item) => (
              <Skeleton
                key={item}
                variant="rectangular"
                width={210}
                height={40}
              />
        ))}
      </Stack>
    </>
)
