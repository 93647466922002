import { SidebarMenuOptionsGroupProps } from './SidebarMenuOptionsGroup.types';
import SidebarMenuOption from '../Item/SidebarMenuOption';

const SidebarMenuOptionsGroup = ({ items, name }: SidebarMenuOptionsGroupProps) => (
    <>
      {name && <h2>{name.toUpperCase()}</h2>}
      {items.map((item, i) => (
          <SidebarMenuOption
              key={i}
              data={item as any}
          />
      ))}
    </>
);

export default SidebarMenuOptionsGroup;
