import { useEffect, useState } from 'react';

import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { ToastContainer } from 'react-toastify';

import { CircularProgress, Stack, Typography } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { LicenseInfo } from '@mui/x-license-pro';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ELocalStorageKeys } from 'constants/enums';
import LogRocket from 'logrocket';
import { UISliceState } from 'store/store.types';
import { uiActions } from 'store/uiSlice';
import { localStorageUtil } from 'utils/localStorageUtil';

import BlockOverlay from './components/BlockOverlay/BlockOverlay';
import { LOG_ROCKET_KEY } from './conf';
import AppRoutes from './routes/AppRoutes';

if (process.env.REACT_APP_ENV === 'production') {
  LogRocket.init(LOG_ROCKET_KEY);
}

LicenseInfo.setLicenseKey(
  (process.env.REACT_APP_DATA_GRID_KEY_NEW as string) ||
    '26352ff0346454e6c919d47b90644e1cTz05ODYyNyxFPTE3NTg3MDk0NjgwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLFBWPVEzLTIwMjQsS1Y9Mg=='
);

function App() {
  const queryClient = new QueryClient();
  const theme = createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 900,
        lg: 1200,
        xl: 1536
      }
    },
    palette: {
      primary: {
        main: '#7122FF'
      },
      secondary: {
        main: '#26282B'
      },
      error: {
        main: '#FF7C7C'
      }
    }
  });

  const dispatch = useDispatch();
  const params = new URLSearchParams(window.location.search);
  // get login token from url
  const token = params.get('t');
  const decodedToken = token ? JSON.parse(atob(token)) : '';

  if (decodedToken) {
    dispatch(uiActions.setTokenLoading(true));
    const isLoggedIn = localStorageUtil.getAny(ELocalStorageKeys.JWT_TOKEN);
    if (!isLoggedIn) {
      // localStorageUtil.setAny(ELocalStorageKeys.JWT_TOKEN, decodedToken.token);
      // localStorageUtil.setAny(ELocalStorageKeys.USER_DETAILS, decodedToken.user);
    }
  }

  const uiState = useSelector(({ ui }: { ui: UISliceState }) => ui);

  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={theme}>
        {uiState.isTokenLoading && (
          <Stack
            width="100vw"
            height="100vh"
            justifyContent="center"
            alignItems="center"
            position="absolute"
            top="0"
            left="0"
            bgcolor="rgba(0, 0, 0, 0.8)"
            zIndex="1000"
            gap={2}
          >
            <CircularProgress
              sx={{
                color: 'white'
              }}
            />
            <Typography variant="h5" color="white">
              Logging you in...
            </Typography>
          </Stack>
        )}
        <BlockOverlay />

        <ToastContainer
          position="bottom-right"
          bodyClassName="notifications-body"
          bodyStyle={{
            alignItems: 'flex-start'
          }}
        />
        <AppRoutes />
      </ThemeProvider>
    </QueryClientProvider>
  );
}

export default App;
